
import { Component, Vue } from 'vue-property-decorator'
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey } from '@/utils/config'
import echarts from 'echarts'
import { SumecDetail, CuttimeList } from '../../types/sumec'
import { drawPolygon } from '@/utils/formatData'

@Component({
  name: 'DetailMap'
})
export default class DetailMap extends Vue {
  private drawer = false
  private map: AMap.Map | undefined = undefined
  private ploygons: Array<AMap.Polygon> = []
  private markers: Array<AMap.Marker> = []

  private onlineImg = require('@/assets/icon/sumec/normal.svg')
  private onlineImgCursor = require('@/assets/icon/sumec/normal-big.svg')
  private offImg = require('@/assets/icon/sumec/offline.svg')
  private offImgCursor = require('@/assets/icon/sumec/offline-big.svg')
  private warningImg = require('@/assets/icon/sumec/warning.svg')
  private warningImgCursor = require('@/assets/icon/sumec/warning-big.svg')
  private baseImg = require('@/assets/icon/sumec/base.svg')

  private deviceList: Array<SumecDetail> = []
  private drawerData: SumecDetail | null = null
  private taskInfoData: CuttimeList[] = []
  private cuttp = ''
  private cuttpNum = ''
  private sumecInfo: SumecDetail[] = []

  get projectId () {
    return this.$route.params.projectId as string
  }

  get electricityProgressColor () {
    let color = ''
    if (this.drawerData && (this.drawerData.quantityElectricity || this.drawerData.quantityElectricity === '0')) {
      color = Number(this.drawerData.quantityElectricity) < 20
        ? '#FF603B' : Number(this.drawerData.quantityElectricity) < 80
          ? '#FFC117' : '#13C2C2'
    }
    return color
  }

  $refs!: {
    statusEcharts: any;
    dayEchart: any;
  }

  created () {
    this.getProjectLocation()
  }

  destroyed (): void {
    if (this.markers) {
      this.markers.forEach((item: AMap.Marker) => {
        item.off('click', this.drawerOpen)
      })
    }
    if (this.ploygons) {
      this.ploygons.forEach(item => {
        item.off('click', this.drawerClose)
      })
    }
    if (this.map) {
      this.map.off('click', this.drawerClose)
      this.map.clearMap()
    }
  }

  // 获取项目信息及项目电子围栏
  getProjectLocation () {
    this.$axios.get(this.$apis.project.selectProjectAreaById, {
      projectId: this.projectId
    }).then((res) => {
      this.$nextTick(() => {
        this.loadMap(res.projectInfo, res.projectLocation)
      })
    })
  }

  // 获取设备信息
  getDeviceList () {
    this.$axios.get<{ onlineCount: string; offlineCount: string; devices: Array<SumecDetail> }>(this.$apis.sumec.selectProjectSumecDeviceList, {
      projectId: this.projectId
    }).then((res) => {
      this.deviceList = res.devices || []
      const data = [{
        name: '在线',
        value: res.onlineCount
      }, {
        name: '离线',
        value: res.offlineCount
      }]
      this.drawEchart(data)
      this.drawMarker(this.deviceList)
    })
  }

  // 在线离线图
  drawEchart (data: Array<{ name: string; value: string }>) {
    const pieEchart: any = echarts.init(this.$refs.statusEcharts)
    const option = {
      title: {
        text: '状态统计',
        textStyle: {
          align: 'center',
          color: '#000',
          fontSize: 14,
          fontWeight: 'bold'
        },
        top: 20,
        left: 24
      },
      color: ['#1AD1B0', '#D2D2D2'],
      legend: {
        icon: 'circle',
        itemHeight: 6,
        show: true,
        itemGap: 15,
        right: 20,
        top: '45%',
        orient: 'vertical',
        textStyle: {
          fontSize: 14 // 字体大小
        },
        formatter: (item: string) => {
          return item === '在线' ? `${item}  ${data[0].value}` : `${item}  ${data[1].value}`
        }
      },

      series: [{
        name: '',
        type: 'pie',
        clockWise: true,
        radius: ['38%', '65%'],
        center: ['35%', '58%'],
        itemStyle: {
          normal: {
            label: {
              show: false
            },
            labelLine: {
              show: false
            }
          }
        },
        hoverAnimation: false,
        data: data
      }]
    }
    pieEchart.setOption(option)
  }

  // 渲染地图
  loadMap (projectInfo: {longitude: number; latitude: number}, list: Array<{ longitude: number; latitude: number; fenceCode: string }>) {
    AMapLoader.load({
      key: mapKey,
      version: '2.0',
      plugins: []
    }).then(() => {
      const center: [number, number] = [Number(projectInfo.longitude), Number(projectInfo.latitude)]
      const map = new AMap.Map('map', {
        center: center,
        zoom: 11
      })
      this.map = map
      this.ploygons = drawPolygon(this.map, list || [], null, this.drawerClose)

      // 获取设备信息
      this.getDeviceList()

      // 点击地图关闭抽屉
      this.map.on('click', this.drawerClose)
    })
  }

  // 获取图片
  getIcon (deviceStatus: string, faultInformation: string, isCurrent: boolean) {
    if (!isCurrent) {
      return (deviceStatus === '1' ? (faultInformation ? this.warningImg : this.onlineImg) : this.offImg)
    } else {
      return (deviceStatus === '1' ? (faultInformation ? this.warningImgCursor : this.onlineImgCursor) : this.offImgCursor)
    }
  }

  // 地图打点
  drawMarker (list: Array<SumecDetail>) {
    this.markers = []
    if (list && list.length > 0) {
      // 循坏将设备marker添加到地图
      list.forEach((item: SumecDetail, index: number) => {
        // 画预设割草面积
        if (item.areaLocationList && item.areaLocationList.length > 0) {
          console.log(item.areaLocationList)
          const areaList: Array<{ longitude: number; latitude: number; fenceCode: string; mapName?: string }> = item.areaLocationList.map(areaItem => {
            return {
              name: areaItem.mapName || '',
              fenceCode: areaItem.fenceCode || '',
              longitude: Number(areaItem.locationLongitude),
              latitude: Number(areaItem.locationLatitude)
            }
          })
          drawPolygon((this.map as AMap.Map), areaList, {
            strokeColor: '#9D4DFF',
            fillColor: '#9D4DFF',
            strokeWeight: 4,
            strokeOpacity: 1,
            fillOpacity: 0.2,
            strokeStyle: 'solid',
            clickable: false
          })
        }
        // 基站添加
        if (item.chargeLongitude && item.chargeLatitude) {
          const markerBase: any = new AMap.Marker({
            icon: new AMap.Icon({
              // 图标的取图地址
              image: this.baseImg
            }),
            offset: new AMap.Pixel(-19, -18),
            position: new AMap.LngLat(Number(item.chargeLongitude), Number(item.chargeLatitude)),
            extData: item
          })
          markerBase.on('click', this.drawerOpen)
          this.map && this.map.add(markerBase)
        }
        // 割草机器人添加
        if (item.longitude && item.latitude) {
          const icon = this.getIcon(item.deviceStatus, item.faultInformation, false)
          const marker: any = new AMap.Marker({
            icon: new AMap.Icon({
              // 图标的取图地址
              image: icon
            }),
            offset: new AMap.Pixel(-18, -48),
            position: new AMap.LngLat(Number(item.longitude), Number(item.latitude)),
            extData: { ...item, type: 'icon' }
          })
          marker.on('click', this.drawerOpen)
          this.map && this.map.add(marker)
          this.markers.push(marker)
        }
      })
    }
  }

  // 抽屉打开
  drawerOpen (e: any) {
    const info = e.target.getExtData()
    if (this.drawerData === null || (this.drawerData && this.drawerData.deviceId !== info.deviceId)) {
      if (this.drawerData && this.drawerData.deviceId !== info.deviceId) {
        this.drawerClose()
      }
      this.drawerData = info
      // 获取设备详情数据
      this.getDeviceDetail(info.deviceId)
      // 点击切换大图标
      const iconClick = this.getIcon(info.deviceStatus, info.faultInformation, true)
      // 获取对应的割草机器人
      if (info.type !== 'icon') {
        const index = (this.map as AMap.Map).getAllOverlays('marker').findIndex((item: any) => item.getExtData() && item.getExtData().deviceId === info.deviceId && item.getExtData().type === 'icon')
        if (index >= 0) {
          const marker = (this.map as AMap.Map).getAllOverlays('marker')[index] as AMap.Marker
          marker.setOffset(new AMap.Pixel(-22, -64))
          marker.setIcon(iconClick)
        }
      } else {
        e.target.setOffset(new AMap.Pixel(-22, -64))
        e.target.setIcon(iconClick)
      }
    }
    this.drawer = true
  }

  // 抽屉关闭
  drawerClose () {
    if (this.drawer) {
      this.drawer = false
      // 将图标替换
      if (this.map && this.drawerData) {
        const deviceId = this.drawerData.deviceId || ''
        const index = this.map.getAllOverlays('marker').findIndex((item: any) => item.getExtData() && item.getExtData().deviceId === deviceId && item.getExtData().type === 'icon')
        if (index >= 0) {
          const icon = this.getIcon(this.drawerData.deviceStatus, this.drawerData.faultInformation, false)
          const marker = this.map.getAllOverlays('marker')[index] as AMap.Marker
          marker.setOffset(new AMap.Pixel(-18, -48))
          marker.setIcon(icon)
        }
      }

      // 清空抽屉
      this.drawerData = null
    }
  }

  // 打开抽屉获取设备详情数据
  getDeviceDetail (deviceId: string) {
    this.$axios.get(this.$apis.sumec.selectSumecConfigByDeviceId, {
      deviceId: deviceId
    }).then((res) => {
      const weekData = ['周一', '周二', '周三', '周四', '周五', '周六', '周日']
      this.taskInfoData = res.cuttimeList
      this.cuttp = res.cuttp
      this.cuttpNum = res.cuttpNum
      for (let i = this.taskInfoData.length - 1; i >= 0; i--) {
        this.taskInfoData[i].week = weekData[i]
        if (this.taskInfoData[i].time2Start === '00:00' && this.taskInfoData[i].time2End === '00:00') {
          this.taskInfoData[i].time2Start = ''
          this.taskInfoData[i].time2End = ''
        }
        if (this.taskInfoData[i].isWork === '2') {
          this.taskInfoData.splice(i, 1)
        }
      }
    })
    this.$axios.get(this.$apis.sumec.selectSumecDeviceDetail, {
      deviceId: deviceId
    }).then((res) => {
      this.sumecInfo = res.sumecInfo || {}
    })
  }

  onDetail (id: string, state: string) {
    // 设备详情
    this.$router.push({
      name: 'sumecDeviceDetailMap',
      params: { projectId: this.projectId, deviceId: id, active: state }
    })
  }
}
